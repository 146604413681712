import { FC } from 'react';
import { Box, Link, LinkProps, Typography } from '@mui/material';
import { isGermany, Tr, UsefulLinkUtils } from '@utils';
import { useSelector } from 'react-redux';
import { useLocationSelector } from '@store/slices/common/location.slice';

const FooterLink: FC<LinkProps> = ({ children, sx, ...props }) => (
	<Link sx={{ color: 'inherit', textDecoration: 'none', ...sx, padding: '0 3px' }} {...props} target="_blank">
		{children}
	</Link>
);

export const Footer: FC<{ login?: boolean }> = ({ login }) => {
	const country = useSelector(useLocationSelector);
	return (
		<Box
			data-testid="footer-wrapper-id"
			sx={{
				minHeight: 72,
				padding: '0 4rem',
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'space-between',
				color: login ? 'white' : 'grey.800',
				opacity: login ? 0.8 : 1,
				borderTop: !login ? '1px solid' : undefined,
				borderColor: 'grey.200',
			}}
		>
			<Typography variant="pg-xs" sx={{ color: 'inherit' }}>
				<Tr.Portal path="footer.all-right-message" />
			</Typography>

			<Typography variant="pg-xs" sx={{ color: 'inherit' }}>
				<FooterLink>
					<Tr.Portal path="footer.version" /> {country?.version}
				</FooterLink>
				|
				<FooterLink href={UsefulLinkUtils.SITE_TERMS}>
					<Tr.Portal path="footer.terms-and-condition" />
				</FooterLink>
				|
				<FooterLink href={UsefulLinkUtils.PRIVACY}>
					<Tr.Portal path="footer.privacy-statement" />
				</FooterLink>
				|
				<FooterLink href={process.env.PUBLIC_URL + '/files/DRMSecurityWhitePaper.pdf'}>
					<Tr.Portal path="footer.security-white-paper" />
				</FooterLink>
				{isGermany(country.code) && (
					<>
						|
						<FooterLink href={UsefulLinkUtils.IMPRINT} data-testid="imprint-test-id">
							<Tr.Portal path="footer.imprint" />
						</FooterLink>
					</>
				)}
			</Typography>
		</Box>
	);
};
