import {
	FullScreenModalDialog,
	GenericCard,
	IGenericCardProps,
	MolecularFormula,
	OverflowText,
	PortalBadge,
	QualityGradeChip,
	SpectrumChart,
	ViewMore,
} from '@components/common';
import { TechnologyChip } from '@components/common/TechnologyChip';
import { useMemoizedTestId } from '@hooks';
import { Button, Divider, Stack, SxProps, Typography } from '@mui/material';
import { NmrDrmDetail, nmrDrmService } from '@services';
import { checkReferenceMaterialNew, DataTestId, DateUtils, mainTitleSpacer, RxUtils, Tr, TypeUtils } from '@utils';
import { getReferenceBorderColor } from '@utils/QualityGrade';
import { FC, forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NmrDRMIndicator } from './DetailComponents';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';
import { QualityGrade } from '@models/reference-material';

interface IDRMDetailProps {
	visible?: boolean;
	drmId?: Maybe<number>;
	actionLabel?: ReactNode;
	drmData?: NmrDrmDetail;
	showModal?: boolean;
	onHandleAction?: (drmId: number) => void;
	onCloseClick?: () => void;
	createReport?: boolean;
	analysisResult?: boolean;
	ref?: any;
	actionBtnSx?: SxProps;
	actionDisabled?: boolean;
	onRequestDrm?: (drmDetail: NmrDrmDetail) => void;
	showInactive?: boolean;
}

export const NmrDRMDetail = forwardRef<HTMLDivElement, IDRMDetailProps>(
	(
		{
			visible = false,
			drmId,
			actionLabel,
			onHandleAction,
			onCloseClick,
			drmData,
			showModal = true,
			createReport = false,
			analysisResult,
			actionBtnSx,
			actionDisabled,
			onRequestDrm,
			showInactive,
		},
		ref,
	) => {
		const { t } = useTranslation('portal');
		const [detailData, setDetailData] = useState<NmrDrmDetail>();
		const [drmFile, setDrmFile] = useState<string>();
		useEffect(() => {
			if (drmData) {
				setDetailData(drmData);
			} else if (drmId) {
				RxUtils.promisify(
					nmrDrmService.get(drmId),
					(drmDetail) => {
						setDetailData(drmDetail);
					},
					onCloseClick,
				);
			}
		}, [drmId, drmData]);

		useMemo(() => {
			if (detailData) {
				RxUtils.promisify(nmrDrmService.getERMFile(detailData.id), (data) => setDrmFile(data));
			}
		}, [detailData]);
		const isNew = useMemo(() => checkReferenceMaterialNew(detailData?.createTime), [detailData]);
		const isActive = analysisResult || !showInactive ? true : detailData?.status === UserReferenceStatus.Active;

		const borderColor = getReferenceBorderColor(detailData?.qualityGrade, !isActive);
		const isTheoretical = detailData?.isTheoretical?.() ?? TypeUtils.transform(NmrDrmDetail, detailData)?.isTheoretical?.() ?? false;

		const titleColor = { color: isActive ? 'text.primary' : 'grey.500' };
		const AdditionalInfo = () => (
			<>
				<Typography variant="h5" sx={{ fontSize: '1rem', fontWeight: 700 }} {...titleColor}>
					<Tr.Portal path="drm-detail.additional-information" />
				</Typography>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.reference-code" />}
					value={detailData?.ermProductNumber ? detailData?.ermProductNumber : '-'}
					isActive={isActive}
				/>
				{!isTheoretical && !detailData?.isUserReference() && (
					<NmrDRMIndicator
						label={<Tr.Portal path="drm-detail.reference-version" />}
						value={detailData?.version ? detailData?.version : '-'}
						isActive={isActive}
					/>
				)}
				<NmrDRMIndicator
					label={
						!detailData?.isUserReference() ? (
							<Tr.Portal path="drm-detail.release-date" />
						) : (
							<Tr.Portal path="drm-detail.creation-date" />
						)
					}
					value={detailData?.createTime ? DateUtils.getFormattedDate(new Date(detailData?.createTime), 'dd/MM/yyyy') : '-'}
					isActive={isActive}
				/>
				<Stack direction="row" alignItems="start" width="100%" sx={{ mt: 1.5 }}>
					<Stack sx={{ width: 222, marginRight: 4 }}>
						<Typography variant="pg-m" sx={{ fontWeight: 400 }} {...titleColor}>
							<Tr.Portal path="drm-detail.synonyms" />
						</Typography>
					</Stack>

					<Stack width="calc(100% - 254px)">
						<ViewMore
							content={detailData?.synonyms || '-'}
							typographyProps={{
								sx: {
									color: isActive ? 'grey.800' : 'grey.500',
									lineHeight: 1.6,
								},
							}}
						/>
					</Stack>
				</Stack>
				{detailData?.isUserReference() && (
					<Stack direction="row" sx={{ mb: 1.5 }}>
						<Stack sx={{ width: 222, marginRight: 4 }}>
							<Typography variant="pg-m" sx={{ fontWeight: 400 }} {...titleColor}>
								<Tr.IrPortal path="drm-detail.comment" />
							</Typography>
						</Stack>
						<Stack width="calc(100% - 254px)">
							<ViewMore
								content={detailData?.generalComment ?? '-'}
								typographyProps={{
									sx: {
										color: isActive ? 'grey.800' : 'grey.500',
										lineHeight: 1.6,
									},
								}}
							/>
						</Stack>
					</Stack>
				)}
			</>
		);
		const PhysicalProductDetails = () => (
			<>
				<Typography variant="h5" sx={{ fontSize: '1rem', fontWeight: 700 }} {...titleColor}>
					<Tr.Portal path="drm-detail.physical-product-details" />
				</Typography>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.physical-product-number" />}
					value={detailData?.physicalProductNumber ? detailData?.physicalProductNumber : '-'}
					isActive={isActive}
					showProductNumber={true}
				/>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.physical-product-quality-grade" />}
					value={detailData?.physicalProductQualityGrade}
					isActive={isActive}
				/>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.physical-product-batch-number" />}
					value={detailData?.physicalProductBatchNumber ? detailData?.physicalProductBatchNumber : '-'}
					isActive={isActive}
				/>
			</>
		);
		const SubstanceDetailsTitle = () => (
			<>
				<Typography variant="h5" sx={{ fontSize: '1rem', fontWeight: 700, mt: 1.5 }} {...titleColor}>
					<Tr.Portal path="drm-detail.substance-details" />
				</Typography>
			</>
		);

		const AcquisitionParameters = () => (
			<>
				<Typography variant="h5" sx={{ fontSize: '1rem', fontWeight: 700 }} {...titleColor}>
					<Tr.Portal path="drm-detail.acquisition-parameters" />
				</Typography>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.solvent" />}
					value={detailData?.solvent ? detailData.solvent : '-'}
					isActive={isActive}
				/>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.frequency" />}
					value={detailData?.frequency ? `${detailData.frequency} ${t('drm-detail.frequency-unit')}` : ''}
					isActive={isActive}
				/>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.relaxation-time" />}
					value={detailData?.relaxationTime ? detailData.relaxationTime : '-'}
					isActive={isActive}
				/>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.number-of-scans" />}
					value={detailData?.numberOfScans ? detailData.numberOfScans : '-'}
					isActive={isActive}
				/>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.acquisition-time" />}
					value={detailData?.acquisitionTime ? detailData.acquisitionTime : '-'}
					isActive={isActive}
				/>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.temperature" />}
					value={detailData?.temperature ? detailData.temperature : '-'}
					isActive={isActive}
				/>
				{detailData?.isUserReference() && (
					<NmrDRMIndicator
						label={<Tr.Portal path="drm-detail.batch-number" />}
						value={detailData?.physicalProductBatchNumber}
						isActive={isActive}
					/>
				)}
			</>
		);

		const DrmDetailDivider = () => <Divider sx={{ color: 'grey.200', height: '1px', my: 2 }} />;

		const ActionButtons = () => (
			<Stack direction="row" justifyContent="flex-end" spacing={2} sx={actionBtnSx}>
				{isTheoretical && onRequestDrm && detailData && (
					<Button
						variant="outlined"
						disableElevation
						onClick={() => {
							drmId && onRequestDrm?.(detailData);
						}}
					>
						{t('erm-request.list-item-request-button')}
					</Button>
				)}
				{actionLabel && onHandleAction ? (
					<Button
						variant="contained"
						data-testid="detail-action-button-id"
						disableElevation
						disabled={actionDisabled}
						onClick={() => {
							drmId && onHandleAction?.(drmId);
						}}
					>
						{actionLabel}
					</Button>
				) : null}
			</Stack>
		);

		const SolventField = () => (
			<>
				<NmrDRMIndicator label={<Tr.Portal path="drm-detail.solvent" />} value={detailData?.solvent} />
			</>
		);

		const FrequenceyField = () => (
			<>
				<NmrDRMIndicator
					label={<Tr.Portal path="drm-detail.frequency" />}
					value={detailData?.frequency ? `${detailData.frequency} ${t('drm-detail.frequency-unit')}` : ''}
				/>
			</>
		);

		const DrmCard: FC<IGenericCardProps> = ({ title, sx, ...props }) => (
			<GenericCard
				sx={{
					paddingX: 13,
					minWidth: 400,
					overflow: 'auto',
					border: 'none',
					paddingTop: 0,
					backgroundColor: 'transparent',
					...sx,
				}}
				title={title}
				{...props}
			>
				<Stack direction="row">
					<Stack>
						<MolecularFormula
							ref={ref}
							width={400}
							height={272}
							sx={{ maxWidth: '400px', width: '100%', height: '272px' }}
							smilesFormula={detailData?.smilesCode}
							borderColor={borderColor}
							zoomIconProps={{
								'data-testid': DataTestId.getStaticTestId('zoom-icon-molecule-id'),
							}}
						/>
						<Stack
							position="relative"
							sx={{
								borderWidth: 2,
								borderStyle: 'solid',
								overflow: 'hidden',
								borderColor: borderColor,
								minWidth: '25rem',
								maxHeight: '272px',
								width: '100%',
								borderRadius: '0.5rem',
								marginTop: mainTitleSpacer,
							}}
						>
							<SpectrumChart
								axisTitle={t('analysis-result.chemical-shift')}
								title={detailData?.name}
								hideZoomIcon={createReport}
								subTitle={<Tr.Portal path="analysis-result.intensity" />}
								subtitleVisibility={true}
								file={{ content: drmFile } as any}
								sx={{ padding: '0' }}
								uniqueId="drm-detail-chart"
								borderColor={borderColor}
								infoMessage={isTheoretical ? t('search-references.theoretical-spectrum-info') : ''}
								layout={{ margin: { pad: 0, t: 30, l: 50, r: 40, b: 260 } }}
							/>
						</Stack>
					</Stack>
					<Stack sx={{ marginLeft: 8, width: 1, maxWidth: 'calc(100% - 28rem)' }}>
						<Stack direction="row" spacing={1.5} marginBottom={2}>
							{detailData && <QualityGradeChip qualityGrade={detailData.qualityGrade} isReferenceActive={isActive} />}
							{detailData && (
								<TechnologyChip
									technology={detailData?.technology}
									qualityGrade={detailData?.qualityGrade}
									isActive={isActive}
									sx={{ height: '1.5rem', marginBottom: 0 }}
								/>
							)}

							{isNew && (
								<PortalBadge
									badgeContent={
										<Typography color={isActive ? 'text.primary' : 'grey.500'} variant="subtitle3" lineHeight="12px">
											<Tr.Common path="new" />
										</Typography>
									}
									sx={{
										alignItems: 'center',
										'& .MuiBadge-badge': {
											backgroundColor: isActive ? 'warning.main' : 'grey.100',
										},
									}}
								/>
							)}
						</Stack>
						<Stack sx={{ width: 1, marginBottom: 0.5 }}>
							<OverflowText
								enableTooltip={true}
								variant="h3"
								data-testid={DataTestId.getStaticTestId('drm-name-id')}
								{...titleColor}
							>
								{detailData?.name || ''}
							</OverflowText>
						</Stack>
						<Stack>
							<SubstanceDetailsTitle />
							<NmrDRMIndicator
								label={<Tr.Portal path="drm-detail.cas-number" />}
								value={detailData?.casNumber}
								isActive={isActive}
							/>
							<NmrDRMIndicator
								label={<Tr.Portal path="drm-detail.linear-formula" />}
								value={detailData?.linearFormula}
								isActive={isActive}
							/>
							<NmrDRMIndicator
								label={<Tr.Portal path="drm-detail.molecular-weight" />}
								value={
									detailData?.molecularWeight
										? `${detailData?.molecularWeight} ${t('drm-detail.molecular-weight-unit')}`
										: ''
								}
								isActive={isActive}
							/>
							<NmrDRMIndicator
								label={<Tr.Portal path="drm-detail.smiles" />}
								value={detailData?.smilesCode ? detailData?.smilesCode : '-'}
								isActive={isActive}
							/>
						</Stack>
						{!isTheoretical && (
							<>
								<DrmDetailDivider />
								{!detailData?.isUserReference() && (
									<>
										<PhysicalProductDetails />
										<DrmDetailDivider />
									</>
								)}

								<AcquisitionParameters />
							</>
						)}
						<DrmDetailDivider />
						<AdditionalInfo />
					</Stack>
				</Stack>
			</GenericCard>
		);
		const zoomIconAnalysisResult = useMemoizedTestId('zoom-icon-matching');
		const chartUniqueId = useMemoizedTestId('chart-unique-id');
		const DrmCardAnalysisResult: FC<IGenericCardProps> = () => (
			<Stack direction="column">
				{/* TODO: ask, why? */}
				<Stack direction="row" marginBottom={5} columnGap={4}>
					<Stack width="30.25rem">
						<NmrDRMIndicator label={<Tr.Portal path="drm-detail.cas-number" />} value={detailData?.casNumber} />
						<NmrDRMIndicator label={<Tr.Portal path="drm-detail.linear-formula" />} value={detailData?.linearFormula} />
						<NmrDRMIndicator
							label={<Tr.Portal path="drm-detail.molecular-weight" />}
							value={
								detailData?.molecularWeight ? `${detailData?.molecularWeight} ${t('drm-detail.molecular-weight-unit')}` : ''
							}
						/>
						<NmrDRMIndicator
							label={<Tr.Portal path="drm-detail.smiles" />}
							value={detailData?.smilesCode ? detailData?.smilesCode : '-'}
							preventOverflow={true}
						/>
						{!(detailData?.qualityGrade === QualityGrade.USER) && (
							<>
								<NmrDRMIndicator
									label={<Tr.Portal path="drm-detail.product-number" />}
									value={detailData?.physicalProductNumber}
									showProductNumber={true}
								/>
								<NmrDRMIndicator
									label={<Tr.Portal path="drm-detail.physical-product-quality-grade" />}
									value={detailData?.physicalProductQualityGrade}
								/>
							</>
						)}
						<SolventField />
						{detailData?.qualityGrade === QualityGrade.USER && <FrequenceyField />}
					</Stack>
					<Stack width="43.25rem">
						{!(detailData?.qualityGrade === QualityGrade.USER) && <FrequenceyField />}
						<NmrDRMIndicator label={<Tr.Portal path="drm-detail.relaxation-time" />} value={detailData?.relaxationTime} />
						<NmrDRMIndicator label={<Tr.Portal path="drm-detail.number-of-scans" />} value={detailData?.numberOfScans} />
						<NmrDRMIndicator label={<Tr.Portal path="drm-detail.acquisition-time" />} value={detailData?.acquisitionTime} />
						<NmrDRMIndicator label={<Tr.Portal path="drm-detail.temperature" />} value={detailData?.temperature} />
						<NmrDRMIndicator
							label={<Tr.Portal path="drm-detail.batch-number" />}
							value={detailData?.physicalProductBatchNumber ? detailData?.physicalProductBatchNumber : '-'}
						/>
						<NmrDRMIndicator label={<Tr.Portal path="drm-detail.reference-code" />} value={detailData?.ermProductNumber} />
					</Stack>
				</Stack>
				<Stack direction="row">
					<MolecularFormula
						ref={ref}
						height={400}
						width={484}
						smilesFormula={detailData?.smilesCode}
						isTheoretical={isTheoretical}
						sx={{
							flexGrow: 2,
						}}
						zoomIconProps={{
							'data-testid': zoomIconAnalysisResult ? `${zoomIconAnalysisResult}` : undefined,
						}}
						borderColor={borderColor}
					/>
					<Stack
						position="relative"
						sx={{
							borderWidth: 2,
							borderStyle: 'solid',
							overflow: 'hidden',
							borderColor: borderColor,
							maxHeight: '25rem',
							marginLeft: '2rem',
							borderRadius: '12px',
							flexGrow: 3,
						}}
					>
						<SpectrumChart
							referenceMaterialId={detailData?.id}
							title={detailData?.name}
							hideZoomIcon={createReport}
							file={{ content: drmFile } as any}
							sx={{ width: '100%', height: 400 }}
							uniqueId={chartUniqueId ? `${chartUniqueId}` : undefined}
							layout={{ margin: { pad: 10, t: 30, l: 70, r: 10, b: 40 } }}
							borderColor={borderColor}
						/>
					</Stack>
				</Stack>
			</Stack>
		);
		return showModal ? (
			<FullScreenModalDialog
				open={visible}
				onClose={onCloseClick}
				actionButtons={<ActionButtons />}
				disableEnforceFocus
				disableAutoFocus
			>
				{analysisResult ? <DrmCardAnalysisResult /> : <DrmCard />}
			</FullScreenModalDialog>
		) : (
			<Stack>
				{analysisResult ? (
					<DrmCardAnalysisResult />
				) : (
					<DrmCard
						title={
							<>
								<Typography variant="h5">{detailData?.title}</Typography>
							</>
						}
					/>
				)}
			</Stack>
		);
	},
);
