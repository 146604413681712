import { GenericCard, OverflowText } from '@components/common';
import { Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { NmrDrmOrder, NmrDrmOrderStatus, NmrDrmOrderStatusChangeErrors } from '@services/nmr-drm-pipeline';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { FC } from 'react';
import { EditDataDetailsModal } from './EditDataDetails';
import { DrmOrderDetailsError } from '../DrmOrderDetailsError';
import { useSelector } from 'react-redux';
import { transitionErrorsSelector } from '@store/slices/nmr/pipeline.slice';
import { ManualEntry } from './ManualEntry/ManualEntry';

type TDataFieldProps = {
	titlePath: string;
	value?: string | number;
	isDeclined?: boolean;
	manualEntry?: boolean;
	isRequiredField?: boolean;
	showProductNumber?: boolean;
};

const SPACE_BETWEEN_FIELDS = 1.5;
const DataField: FC<TDataFieldProps> = ({ titlePath, value, isDeclined, manualEntry, isRequiredField, showProductNumber }) => {
	return (
		<Stack sx={{ marginBottom: SPACE_BETWEEN_FIELDS }}>
			<Grid container>
				<Grid item xs={5}>
					<Stack direction="row">
						<OverflowText
							sx={{ width: isRequiredField ? '98%' : '100%' }}
							enableTooltip
							variant="pg-m"
							color={isDeclined ? 'grey.500' : 'text.primary'}
						>
							<Tr.Admin path={titlePath} />
						</OverflowText>
						{isRequiredField && (
							<Typography variant="pg-m" color="error.main">
								*
							</Typography>
						)}
					</Stack>
				</Grid>
				<Grid item xs={7}>
					<Stack direction="row" sx={{ paddingLeft: 2, justifyContent: 'space-between', alignItems: 'center' }}>
						{!showProductNumber ? (
							<Typography variant="pg-m" color={isDeclined ? 'grey.500' : 'grey.800'}>
								{value || '-'}
							</Typography>
						) : (
							<Link
								underline="hover"
								variant="body1"
								href={`https://www.sigmaaldrich.com/US/en/product/sial/${value}`}
								sx={{ cursor: 'pointer' }}
								target="_blank"
							>
								{value}
							</Link>
						)}
						{manualEntry && value && <ManualEntry />}
					</Stack>
				</Grid>
			</Grid>
		</Stack>
	);
};

const ColumnDivider = () => {
	return (
		<Stack sx={{ position: 'absolute', left: '50%', top: 0, height: '100%' }} paddingBottom={SPACE_BETWEEN_FIELDS}>
			<Divider orientation="vertical" />
		</Stack>
	);
};
type TDrmOrderDataDetailsProps = {
	nmrDrmOrder?: NmrDrmOrder;
	refreshData?: () => void;
};

export const DrmOrderDataDetails: FC<TDrmOrderDataDetailsProps> = ({ nmrDrmOrder, refreshData }) => {
	const transitionErrors = useSelector(transitionErrorsSelector);

	const isDeclined = nmrDrmOrder?.status === NmrDrmOrderStatus.DECLINED;
	const isErrorVisible = transitionErrors.includes(NmrDrmOrderStatusChangeErrors.DATA_DETAILS_MISSING);
	const isRequiredField =
		nmrDrmOrder?.status === NmrDrmOrderStatus.IN_PRODUCTION || nmrDrmOrder?.status === NmrDrmOrderStatus.WAITING_FOR_MEASUREMENT;

	return (
		<GenericCard sx={{ flexGrow: 1, position: 'relative' }}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={3}>
				<Typography sx={{ color: isDeclined ? 'grey.500' : 'text.primary' }} variant="h4">
					<Tr.Admin path="drm-order-details.data-details" />
				</Typography>
				{nmrDrmOrder && <EditDataDetailsModal refreshData={() => refreshData?.()} nmrDrmOrder={nmrDrmOrder} />}
			</Stack>
			<Stack sx={{ position: 'relative' }}>
				<Grid container columnSpacing={7}>
					<ColumnDivider />
					<Grid item xs={6}>
						<DataField
							titlePath="drm-order-details.physical-product-number"
							value={nmrDrmOrder?.physicalProductNumber}
							isDeclined={isDeclined}
							manualEntry={!nmrDrmOrder?.isProductActive}
							isRequiredField={isRequiredField}
							showProductNumber={true}
						/>
						<DataField
							titlePath="drm-order-details.quality-grade"
							value={nmrDrmOrder?.qualityGrade}
							isDeclined={isDeclined}
							manualEntry={!nmrDrmOrder?.isProductActive}
						/>
						<DataField
							titlePath="drm-order-details.batch-number"
							isRequiredField={isRequiredField}
							value={nmrDrmOrder?.batchNumber}
							isDeclined={isDeclined}
						/>
						<DataField
							titlePath="drm-order-details.site-supplier"
							value={nmrDrmOrder?.siteSupplier}
							isDeclined={isDeclined}
							manualEntry={!nmrDrmOrder?.isProductActive}
						/>
						<DataField titlePath="drm-order-details.purity" value={nmrDrmOrder?.purity} isDeclined={isDeclined} />
						<DataField titlePath="drm-order-details.pH" value={nmrDrmOrder?.purity} isDeclined={isDeclined} />
						<DataField titlePath="drm-order-details.solvent" value={nmrDrmOrder?.solvent} isDeclined={isDeclined} />
						<DataField
							titlePath="drm-order-details.measurement-date"
							value={nmrDrmOrder?.measurementDate ? DateUtils.getFormattedDate(nmrDrmOrder?.measurementDate) : ''}
							isDeclined={isDeclined}
						/>
					</Grid>
					<Grid item xs={6}>
						<DataField titlePath="drm-order-details.receiver-gain" value={nmrDrmOrder?.receiverGain} isDeclined={isDeclined} />
						<DataField
							titlePath="drm-order-details.number-of-scans"
							value={nmrDrmOrder?.numberOfScans}
							isDeclined={isDeclined}
						/>
						<DataField
							titlePath="drm-order-details.pulse-width"
							value={nmrDrmOrder?.pulseWidth && nmrDrmOrder.pulseWidth + ' µs'}
							isDeclined={isDeclined}
						/>
						<DataField
							titlePath="drm-order-details.frequency"
							value={nmrDrmOrder?.frequency && nmrDrmOrder?.frequency + ' MHz'}
							isDeclined={isDeclined}
						/>
						<DataField
							titlePath="drm-order-details.relaxation-time"
							value={nmrDrmOrder?.relaxationTime && nmrDrmOrder?.relaxationTime + ' sec'}
							isDeclined={isDeclined}
						/>
						<DataField
							titlePath="drm-order-details.acquisition-time"
							value={nmrDrmOrder?.acquisitionTime}
							isDeclined={isDeclined}
						/>
						<DataField titlePath="drm-order-details.temperature" value={nmrDrmOrder?.temperature} isDeclined={isDeclined} />
					</Grid>
				</Grid>
			</Stack>
			{isErrorVisible && (
				<Stack marginTop={2}>
					<DrmOrderDetailsError />
				</Stack>
			)}
		</GenericCard>
	);
};
