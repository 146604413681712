import { FC, useState, useEffect, ReactNode } from 'react';
import { PlotParams } from 'react-plotly.js';
import { Stack, SxProps } from '@mui/material';
import { useNmrParser } from '@hooks';
import { IUploadedFile } from '@models';
import { RxUtils } from '@utils/Rx';
import { fileService, nmrAnalysisService, nmrDrmService } from '@services';
import { findMax, findMin } from '@utils';
import { Chart } from '../Chart';
import { useTranslation } from 'react-i18next';

interface ISpectrumChartType {
	analysisId?: number;
	file?: IUploadedFile;
	fileUrl?: string;
	hideZoomIcon?: boolean;
	sx?: SxProps;
	enableZoom?: boolean;
	color?: string;
	layout?: PlotParams['layout'];
	title?: string;
	axisTitle?: string;
	uniqueId?: string | number;
	referenceMaterialId?: number;
	infoMessage?: string;
	isTheoretical?: boolean;
	subTitle?: ReactNode;
	subtitleVisibility?: boolean;
	isZoomIconEnabled?: boolean;
	isDeactivated?: boolean;
	header?: string | ReactNode;
	createRefernceId?: number;
	borderColor?: string;
	editing?: boolean;
}

export const SpectrumChart: FC<ISpectrumChartType> = ({
	analysisId,
	file,
	fileUrl,
	hideZoomIcon = false,
	sx,
	enableZoom = true,
	color,
	layout,
	title,
	axisTitle,
	uniqueId,
	referenceMaterialId,
	infoMessage,
	isTheoretical,
	subTitle,
	subtitleVisibility = false,
	isZoomIconEnabled = true,
	isDeactivated = false,
	createRefernceId,
	editing,
	header,
	borderColor = '',
}) => {
	const { t } = useTranslation('portal');
	const [spectrumFile, setSpectrumFile] = useState<IUploadedFile>();
	const { xData, yData, meta } = useNmrParser(spectrumFile && spectrumFile.content ? spectrumFile.content : undefined);

	useEffect(() => {
		if (file) {
			setSpectrumFile(file);
		} else if (analysisId) {
			RxUtils.promisify(nmrAnalysisService.getFiles(analysisId), (data) => {
				if (data.length > 0) {
					RxUtils.promisify(nmrAnalysisService.downloadFile(analysisId, data[0].id), (specFile) => {
						setSpectrumFile({ content: specFile } as any);
					});
				}
			});
		} else if (fileUrl) {
			RxUtils.promisify(fileService.getFileFromUrl(fileUrl), (data) => {
				setSpectrumFile({ content: data } as any);
			});
		} else if (editing && createRefernceId) {
			RxUtils.promisify(nmrDrmService.getERMFile(createRefernceId), (data) => {
				setSpectrumFile({ content: data } as any);
			});
		}
	}, [analysisId, file, fileUrl]);
	const sampleMaxVal = yData ? findMax(yData) : 0;
	const sampleMinVal = yData ? findMin(yData) : 0;
	const sampleDiffMinMax = sampleMaxVal - sampleMinVal;

	return (
		<Stack sx={{ width: '100%', display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
			<Chart
				subtitleVisibility={subtitleVisibility}
				subTitle={subTitle}
				className={`c${referenceMaterialId}-spectrum-chart`}
				enableZoom={enableZoom}
				title={title || meta?.TITLE}
				hideZoomIcon={hideZoomIcon}
				isZoomIconEnabled={isZoomIconEnabled}
				divId={uniqueId ? `${uniqueId}` : undefined}
				infoMessage={infoMessage}
				isTheoretical={isTheoretical}
				isDeactivated={isDeactivated}
				header={header}
				zoomIconProps={{
					'data-testid': `zoom-icon-${uniqueId}`,
				}}
				data={[
					{
						y: yData?.map((item: number) => (item - sampleMinVal) / sampleDiffMinMax),
						x: xData,
						type: 'scatter',
						mode: 'lines',
						marker: { color: color || 'gray' },
						direction: 'counterclockwise',
						name: layout?.showlegend ? t('analysis-result.input-spectrum') : undefined,
					},
				]}
				sx={sx}
				useResizeHandler
				style={{ width: '100%', height: '100%' }}
				layout={{
					autosize: true,
					xaxis: { range: [10, 0], showdividers: false, showline: false, zeroline: false, title: axisTitle },
					...layout,
				}}
				borderColor={borderColor}
			/>
		</Stack>
	);
};
