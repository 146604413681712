import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formFieldSpacer, innerTitleSpacer, Scroll, Tr } from '@utils';
import { Grid, Stack, SxProps, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield, GenericCard } from '@components/common';
import { AddSubstanceOverlay } from '@components/common/AddSubstance';
import { useParams } from 'react-router-dom';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';

interface NmrReferenceSubstanceDetailsProps {
	userReferenceData?: any;
	formik: FormikApiType;
	sx?: SxProps;
	editing?: boolean;
}

export const NmrReferenceSubstanceDetails: FC<NmrReferenceSubstanceDetailsProps> = ({ userReferenceData, formik, sx, editing = false }) => {
	const { t } = useTranslation('portal');
	const { status } = useParams<{ status: string }>();
	const disableRmInActiveFields = status === UserReferenceStatus.Inactive;

	useEffect(() => {
		if (editing) {
			formik.validateForm();
		}
	}, [userReferenceData]);

	return (
		<GenericCard sx={sx}>
			<Stack direction="row" justifyContent="space-between">
				<Stack marginBottom={innerTitleSpacer}>
					<Typography variant="h3" color={disableRmInActiveFields ? 'grey.400' : 'primary.main'}>
						<Tr.Portal path="drm-detail.substance-details" />
					</Typography>
				</Stack>
				<AddSubstanceOverlay isUserReferenceType referenceFormik={formik} disabled={disableRmInActiveFields} />
			</Stack>
			<Grid container spacing={formFieldSpacer} sx={{ alignItems: 'flex-start', justifyContent: 'start' }}>
				<Grid item xs={6}>
					<Grid container spacing={formFieldSpacer}>
						<Grid item xs={12}>
							<FormikTextfield
								formikApi={formik}
								name="casNumber"
								title={t('drm-detail.cas-number')}
								placeholder={t('user-references.placeholders.cas-number')}
								variant="outlined"
								disabled={disableRmInActiveFields}
								onChange={() => {
									formik.setFieldTouched('casNumber', true);
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormikTextfield
								formikApi={formik}
								name="molecularWeight"
								title={t('drm-detail.molecular-weight')}
								placeholder={t('user-references.placeholders.molecular-weight')}
								variant="outlined"
								disabled={disableRmInActiveFields}
								onChange={() => {
									formik.setFieldTouched('molecularWeight', true);
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormikTextfield
								formikApi={formik}
								name="linearFormula"
								title={t('drm-detail.linear-formula')}
								placeholder={t('user-references.placeholders.molecular-formula')}
								variant="outlined"
								disabled={disableRmInActiveFields}
								onChange={() => {
									formik.setFieldTouched('linearFormula', true);
								}}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={6}>
					<Grid container spacing={formFieldSpacer}>
						<Grid item xs={12}>
							<FormikTextfield
								formikApi={formik}
								name="smilesCode"
								title={t('drm-detail.smiles')}
								placeholder={t('user-references.placeholders.smiles')}
								variant="outlined"
								disabled={disableRmInActiveFields}
								required
								onChange={() => {
									if (editing) {
										formik.setFieldTouched('smilesCode', true);
									}
								}}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormikTextfield
								formikApi={formik}
								multiline
								maxRows={4.6}
								minRows={4.6}
								name="synonyms"
								title={t('drm-detail.synonyms')}
								placeholder={t('user-references.placeholders.synonyms')}
								sx={{
									backgroundColor: 'grey.50',
								}}
								inputProps={{
									inputComponent: 'textarea',
									maxLength: 4000,
									sx: {
										...Scroll.Y,
										paddingRight: 0.5,
									},
								}}
								variant="outlined"
								disabled={disableRmInActiveFields}
								onChange={() => {
									formik.setFieldTouched('synonyms', true);
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</GenericCard>
	);
};
