import { OverflowText } from '@components/common';
import { Link, Stack, SxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IndicatorType {
	label?: ReactNode;
	value?: string | number;
	containerSx?: SxProps;
	preventOverflow?: boolean;
	isActive?: boolean;
	showProductNumber?: boolean;
}

export const NmrDRMIndicator: FC<IndicatorType> = ({
	label,
	value = '',
	preventOverflow = false,
	containerSx,
	isActive = true,
	showProductNumber,
}) => (
	<Stack direction="row" alignItems="center" sx={{ mt: 1.5, ...containerSx }}>
		<Typography
			variant="pg-m"
			sx={{ width: 222, minWidth: 222, fontWeight: 400, color: isActive ? 'text.primary' : 'grey.500', marginRight: 4 }}
		>
			{label}
		</Typography>
		{preventOverflow && !showProductNumber && (
			<OverflowText variant="pg-m" sx={{ color: isActive ? 'grey.800' : 'grey.500', lineHeight: 1.6 }} enableTooltip>
				{value || '-'}
			</OverflowText>
		)}
		{!preventOverflow && !showProductNumber && (
			<Typography variant="pg-m" sx={{ color: isActive ? 'grey.800' : 'grey.500', lineHeight: 1.6 }}>
				{value || '-'}
			</Typography>
		)}
		{showProductNumber && (
			<Link
				underline="hover"
				variant="body1"
				href={`https://www.sigmaaldrich.com/US/en/product/sial/${value}`}
				sx={{ cursor: 'pointer' }}
				target="_blank"
			>
				{value}
			</Link>
		)}
	</Stack>
);
